import React, { createContext, useContext, useState, useMemo } from 'react';
import { useUser } from '../hooks';
import { usePaginatedNotifications } from '../hooks/api/communication';
import { User } from '@dinbog/models';
import {
  usePaginatedUserRequests,
  useUserRecommendations,
  useUserRequestsCount,
} from '../hooks/api/users';

interface RequestsContextProps {
  data: any;
  notificationsData: any;
  fetchNotificationsNextPage: any;
  fetchRequestsNextPage: any;
  requestsData: any;
  refetchRequests: any;
  isErrorInfiniteRequest: any;
  isErrorInfiniteNotification: any;
  loadingNotifications: any;
  loading: any;
  recommendations: any;
  loadingRecommendations: any;
  tabSelected: any;
  setTabSelected: any;
  count: any;
}

const RequestsContext = createContext<RequestsContextProps | undefined>(
  undefined
);

export const RequestsProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { currentUser: user } = useUser();
  const [pagination, setPagination] = useState({
    page: 1,
    perPage: 10,
  });
  const [tabSelected, setTabSelected] = useState<
    'received' | 'sent' | 'notifications'
  >('received');

  const {
    InfiniteRequestData,
    fetchNextPage: fetchNextPageRequests,
    isLoadingInfiniteRequest: isLoading,
    isErrorInfiniteRequest,
    refetchInfiniteRequest,
  } = usePaginatedUserRequests({
    user: (user?.currentUser?.user as User)?._id as string,
    token: user?.token,
    type: tabSelected !== 'notifications' ? tabSelected : null,
    page: pagination.page,
    perPage: pagination.perPage,
  });

  const {
    InfiniteNotificationData,
    fetchNextPage,
    isLoadingInfiniteNotification,
    isErrorInfiniteNotification,
  } = usePaginatedNotifications(user);

  const { data: dataCount } = useUserRequestsCount(
    {
      user: (user?.currentUser?.user as User)?._id as string,
      token: user?.token,
    },
    { enabled: !!user?.token && !!user?.currentUser?.user }
  );

  const {
    data: recommendationsTalents,
    isLoading: loadingRecommendationsTalents,
    refetch: refetchTalents,
  } = useUserRecommendations(
    {
      user: (user?.currentUser?.user as User)?._id as string,
      page: 1,
      perPage: 8,
      type: 'talent',
    },
    { enabled: !!user?.currentUser?.user }
  );
  const {
    data: recommendationsBusinesses,
    isLoading: loadingRecommendationsBusinesses,
    refetch: refetchBusinesses,
  } = useUserRecommendations(
    {
      user: (user?.currentUser?.user as User)?._id as string,
      page: 1,
      perPage: 8,
      type: 'business',
    },
    { enabled: !!user?.currentUser?.user }
  );

  const notificationsData = useMemo(() => {
    const pages = InfiniteNotificationData?.pages || [];
    const flattenedItems = pages.reduce(
      (acc, page) => [...acc, ...page.items],
      [] as Notification[]
    );
    const totalCount = pages[0]?.count || 0;
    const pageInfo = pages[pages.length - 1]?.pageInfo;

    return {
      count: totalCount,
      items: flattenedItems,
      pageInfo,
    };
  }, [InfiniteNotificationData]);

  const requestsData = useMemo(() => {
    const pages = InfiniteRequestData?.pages || [];
    const flattenedItems = pages.reduce(
      (acc, page) => [...acc, ...page.items],
      [] as Request[]
    );
    const totalCount = pages[0]?.count || 0;
    const pageInfo = pages[pages.length - 1]?.pageInfo;
    return {
      count: totalCount,
      items: flattenedItems,
      pageInfo,
    };
  }, [InfiniteRequestData]);

  const value = useMemo(() => ({
    data: requestsData,
    notificationsData,
    fetchNotificationsNextPage: fetchNextPage,
    fetchRequestsNextPage: fetchNextPageRequests,
    requestsData,
    refetchRequests: refetchInfiniteRequest,
    isErrorInfiniteRequest,
    isErrorInfiniteNotification,
    loadingNotifications: isLoadingInfiniteNotification,
    loading: isLoading,
    recommendations: {
      talents: recommendationsTalents?.items ?? [],
      businesses: recommendationsBusinesses?.items ?? [],
      refetchTalents,
      refetchBusinesses,
    },
    loadingRecommendations:
      loadingRecommendationsTalents || loadingRecommendationsBusinesses,
    tabSelected,
    setTabSelected,
    count: dataCount,
  }), [
    requestsData,
    notificationsData,
    fetchNextPage,
    fetchNextPageRequests,
    refetchInfiniteRequest,
    isErrorInfiniteRequest,
    isErrorInfiniteNotification,
    isLoadingInfiniteNotification,
    isLoading,
    recommendationsTalents,
    recommendationsBusinesses,
    refetchTalents,
    refetchBusinesses,
    loadingRecommendationsTalents,
    loadingRecommendationsBusinesses,
    tabSelected,
    setTabSelected,
    dataCount,
  ]);

  return (
    <RequestsContext.Provider value={value}>
      {children}
    </RequestsContext.Provider>
  );
};

export const useRequests = () => {
  const context = useContext(RequestsContext);
  if (context === undefined) {
    throw new Error('useRequests must be used within a RequestsProvider');
  }
  return context;
};
