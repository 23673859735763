import React from 'react';
import { useTranslation } from 'react-i18next';

interface SpecRowProps {
  spec;
  fromTemplate: boolean;
}

function SpecRow({ spec, fromTemplate }: SpecRowProps) {
  const { t, i18n } = useTranslation();

  const formatSpecValues = () => {
    switch (spec.type) {
      case 'range':
        return (
          <div className="">
            {spec?.multipleValues[0]} - {spec?.multipleValues[1]}{' '}
            {spec?.specificationTemplate?.measure}
          </div>
        );
      case 'multiselect':
        return spec?.multipleValues?.map((value, idx) => (
          <div className="space-x-1">
            <span className="">
              {
                spec?.specificationTemplate?.options?.find(
                  (option) => option?.value === value
                )?.name?.[i18n?.language ?? 'en']
              }
            </span>
            <span>{spec?.multipleValues?.length > idx + 1 ? '|' : null}</span>
          </div>
        ));
      case 'boolean':
        return (
          <div className="">{spec?.value === 'true' ? t('yes') : t('no')}</div>
        );

      default:
        break;
    }
  };

  return (
    <div className="flex w-full font-medium">
      <span className="text-text-black">
        {fromTemplate
          ? spec?.specificationTemplate?.name[i18n?.language ?? 'en']
          : spec?.name}
      </span>
      <p className="text-neutral-500 ml-auto text-right">
        {spec?.value !== 'undefined' &&
        spec?.type !== 'boolean' &&
        spec?.type !== 'multiselect'
          ? spec?.value
          : formatSpecValues()}
      </p>
    </div>
  );
}

export default SpecRow;
