import React from 'react';
import { useTranslation } from 'react-i18next';
import { Country } from 'country-state-city';
import { getFlag } from '../../../lib/getFlag';

type Business = {
  headquarter: string;
  city: string;
  state: string;
  websiteUrl: string;
  _id: string;
  createdAt: string;
  updatedAt: string;
};

interface LocationCategoryProps {
  user;
  withLocation?: boolean;
  textSize?: string;
  business?: Business;
}

export default function LocationCategory({
  user,
  withLocation = false,
  textSize = 'text-sm',
  business = null,
}: LocationCategoryProps) {
  const { i18n } = useTranslation();

  const countryFlag =
    getFlag(user?.profile?.address?.country) ?? getFlag(business?.headquarter);

  let city;
  let country;

  if (user?.profile?.address) {
    city = user.profile.address.city;
    country = Country.getCountryByCode(user.profile.address.country)?.name;
  } else if (business) {
    city = business.city;
    country = Country.getCountryByCode(business.headquarter)?.name;
  } else {
    city = '';
    country = '';
  }

  const AddressString = `${city}, ${country}`;

  return (
    <div className={`${textSize} gap-1 w-full text-left`}>
      {withLocation ? (
        <span className="whitespace-nowrap overflow-hidden text-ellipsis">
          {user?.profile?.categories?.[0]?.name?.[i18n?.language ?? 'en']}
        </span>
      ) : null}
      <div className="gap-1 flex w-full">
        <span className="">{countryFlag}</span>
        <span className="whitespace-nowrap overflow-hidden text-ellipsis w-full">
          {withLocation
            ? AddressString
            : user?.profile?.categories?.[0]?.name?.[i18n?.language ?? 'en']}
        </span>
      </div>
    </div>
  );
}
