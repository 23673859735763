import React from 'react';
import Link from 'next/link';
import { useTranslation } from 'react-i18next';
import { ChatBubbleLeftIcon } from '@heroicons/react/24/outline';
import {
  faCalendar,
  faAd,
  faTrophy,
  faBriefcase,
  faClapperboard,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dropdown, FadeAnimation } from '../../../../../../shared';
import { useScreenSize } from '../../../../../../../hooks';
import BriefcaseDropdownItem from './BriefcaseDropdownItem';

function BriefcaseDropdown() {
  const { t } = useTranslation();
  const { screenSize } = useScreenSize();

  const iconClass = 'w-4 h-4';

  const [isOpen, setIsOpen] = React.useState(false);

  const options = [
    {
      icon: <FontAwesomeIcon icon={faClapperboard} className={iconClass} />,
      name: t('navbar.logged.briefcaseDropdown.castings'),
      route: '/briefcase/castings',
    },
    /* {
      icon: faCalendar,
      name: t('navbar.logged.briefcaseDropdown.events'),
      route: '/briefcase/events',
    },
    {
      icon: faAd,
      name: t('navbar.logged.briefcaseDropdown.ads'),
      route: '/briefcase/ads',
    },
    {
      icon: faTrophy,
      name: t('navbar.logged.briefcaseDropdown.contests'),
      route: '/briefcase/contests',
    }, */
  ];

  /* <div>
      {screenSize >= 768 ? (
        <Dropdown
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          className="h-full flex items-center"
        >
          <Dropdown.Button>
            <FontAwesomeIcon
              icon={faBriefcase}
              className={`${
                isOpen ? 'text-primary-500' : 'text-text-gray'
              } w-6 h-6`}
            />
          </Dropdown.Button>
          <Dropdown.ItemsContainer className="rounded-sm mt-8 p-0">
            {options.map((option) => (
              <BriefcaseDropdownItem item={option} />
            ))}
          </Dropdown.ItemsContainer>
        </Dropdown>
      ) : (
        <Link href="/notifications">
          <ChatBubbleLeftIcon className="text-text-gray w-6 h-6" />
        </Link>
      )}
    </div> */

  return (
    <Dropdown isOpen={isOpen} setIsOpen={setIsOpen}>
      <Dropdown.Button>
        <FontAwesomeIcon
          icon={faBriefcase}
          className={`w-6 h-6 ${
            isOpen ? 'text-primary-500' : 'text-text-gray'
          }`}
        />
      </Dropdown.Button>
      <Dropdown.ItemsContainer className="mt-4 flex flex-col gap-y-2">
        <FadeAnimation duration={0.5}>
          {options.map((option) => (
            <BriefcaseDropdownItem key={option?.name} item={option} />
          ))}
        </FadeAnimation>
      </Dropdown.ItemsContainer>
    </Dropdown>
  );
}

/* <Dropdown isOpen={isOpen} setIsOpen={setIsOpen}>
      <Dropdown.Button hasTriangleIcon>
        <img
          className={`${
            user?.currentUser?.type === 'person' ? 'rounded-full' : 'rounded'
          } w-8 h-8 object-cover`}
          src={
            user?.currentUser?.type === 'person'
              ? user?.currentUser?.photo?.url ?? userFallback.src
              : user?.currentUser?.photo?.url ?? userFallback.src
          }
          alt="user"
        />
      </Dropdown.Button>
      <Dropdown.ItemsContainer className="mt-4 flex flex-col gap-y-2">
        {openSwitchAccount ? (
          <SwitchAccountCard setOpen={setOpenSwitchAccount} />
        ) : (
          <FadeAnimation duration={0.5}>
            {userDropdownItems.map((item) =>
              item?.label === 'Log out' &&
              ((user?.currentUser?.user as User)?._id as string) !== user?._id ? null : (
                <UserDropdownItem
                  key={item?.label}
                  type={item?.type}
                  label={item?.label}
                  icon={item?.icon}
                  href={item?.href ?? ''}
                  onClick={
                    item?.label === 'Log out' ? handleSignOut : item.onClick
                  }
                />
              )
            )}
          </FadeAnimation>
        )}
      </Dropdown.ItemsContainer>
    </Dropdown> */

export default BriefcaseDropdown;
