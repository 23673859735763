import React from 'react';
import { useRouter } from 'next/router';
import {
  FetchNextPageOptions,
  InfiniteQueryObserverResult,
} from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { Vacant, Pagination, PageInfo } from '@dinbog/models';
import { Button } from '@dinbog/ui';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import VacancyDetail from './vacancyDetail/VacancyDetail';
import { InfoWithImage } from '../../../../../shared';
import InfiniteList from '../../../../../shared/infinite-list/InfiniteList';
import VacancyCard from './VacancyCard';

interface VacancyTabProps {
  vacancies: Pagination<Vacant>;
  loading: boolean;
  setPagination: React.Dispatch<React.SetStateAction<PageInfo>>;
  isOwner?: boolean;
  fetchNextPage: (options?: FetchNextPageOptions) => Promise<
    InfiniteQueryObserverResult<
      Pagination<
        Vacant & {
          applicants: number;
        }
      >,
      AxiosError<unknown, any>
    >
  >;
}

export default function VacancyTab({
  vacancies,
  loading = false,
  setPagination,
  isOwner = false,
  fetchNextPage,
}: VacancyTabProps) {
  const router = useRouter();
  const { t } = useTranslation();
  const [loadedData, setLoadedData] = React.useState<
    Array<Vacant & { applicants: number }>
  >([]);

  return (
    <div className="flex flex-col gap-4">
      {/* add vacancy btn */}
      <div className="w-full flex">
        {!router.query.vacancy ? (
          <Button
            tooltip={t('events.casting.vacancies.addBtn')}
            className="text-primary-500 flex gap-2 items-center self-end"
            onClick={() =>
              router.push({
                pathname: `/briefcase/castings/[slug]/vacancies/create`,
                query: { slug: router.query.slug },
              })
            }
          >
            <FontAwesomeIcon
              icon={faPlus}
              className="h-5 w-5 text-primary-500"
            />
            <span className="">{t('events.casting.vacancies.addBtn')}</span>
          </Button>
        ) : null}
      </div>
      {/* vacancies list */}

      {loadedData?.length === 0 && vacancies?.items?.length === 0 ? (
        <InfoWithImage
          image="/images/fallback/my-castings-tab.png"
          title={
            isOwner
              ? t('emptyStates.noVacancies.self.title')
              : t('emptyStates.noVacancies.other.title')
          }
          text={isOwner ? t('emptyStates.noVacancies.self.text') : ''}
        />
      ) : (
        <InfiniteList items={vacancies?.items} fetchNextPage={fetchNextPage}>
          {(vacancy) =>
            router.query.vacancy && !isOwner ? (
              <VacancyDetail
                vacancy={router.query.vacancy}
                key={vacancy?._id}
              />
            ) : (
              <VacancyCard key={vacancy?._id} vacancy={vacancy} />
            )
          }
        </InfiniteList>
      )}
    </div>
  );
}
