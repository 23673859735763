/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import { useRouter } from 'next/router';
import { Dropdown } from '../../../../../../shared';

interface BriefcaseDropdownItemProps {
  item: {
    icon: any;
    name: string;
    route: string;
  };
}

function BriefcaseDropdownItem({ item }: BriefcaseDropdownItemProps) {
  const router = useRouter();
  return (
    <button
      type="button"
      className="flex w-full text-left border-none"
      onClick={() => router.push(item?.route)}
    >
      <Dropdown.Item text={item?.name} icon={item?.icon} />
    </button>
  );
}

export default BriefcaseDropdownItem;
